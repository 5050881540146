import React from "react";
import logo from "../assets/logo.png";

const Loading = () => {
  return (
    <section className="main" id="loader-container">
      <div className="container">
        <img src={logo} alt="" />
        <div className="loader"></div>
        <h3 className="title">Challenge Plus</h3>
      </div>
    </section>
  );
};

export default Loading;
