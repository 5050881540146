import React from "react";
import "./App.css";
import Loader from "./components/Loading";

function App() {
  React.useEffect(() => {
    const redirectToDownloadPage = setTimeout(() => {
      const link = "https://link-to.app/challengeplusgift";
      window.location.href = link;

      return () => {
        clearTimeout(redirectToDownloadPage);
      };
    }, 2000);
  }, []);

  return <Loader />;
}

export default App;
